import React, { Component } from "react"
import { graphql } from "gatsby"
import DefaultLayout from "../layout"
import SEO from "../components/seo"

import "../assets/styles/components/page-modules.css"

class Page extends Component {
  render() {
    const StaticPage = this.props.data.wordpressPage

    return (
      <DefaultLayout className="page">
        <SEO
          title={StaticPage.title}
          description={StaticPage.excerpt}
        />
        <div className="page-inner wrapper wrapper--small">
          <h1 dangerouslySetInnerHTML={{ __html: StaticPage.title }} />
          <div className="entry-content" dangerouslySetInnerHTML={{ __html: StaticPage.content }} />
        </div>
      </DefaultLayout>
    )
  }
}

export default Page

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      excerpt
    }
    site {
      id
      siteMetadata {
        title
        description
      }
    }
  }
`